<template>
  <div class="order-select container">
    <versions v-model:code="code" v-model="chooseItem"
    ></versions>
  </div>
</template>
<script setup>
import {getCurrentInstance, ref, watch} from "vue";
import Versions from "./upLevelVersion.vue";
import {useStore} from "vuex";

const store = useStore()

const {proxy} = getCurrentInstance();
const code = ref('')
const chooseItem = ref({
  comboId: '',
  countNumber: 1,
})
defineExpose({
  code,
  chooseItem
})
//监听code,chooseItem 的变化
watch([() => code.value, () => chooseItem.value], () => {
  proxy.$emit('changeObj')
}, {deep: true})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "orderSelect",
});
</script>

<style lang="scss" scoped>
.order-select {
  display: flex;
  justify-content: space-between;
  padding: 60px;
}
</style>
