<template>
  <div class="shop-order">
    <div class="order-head">
      <shop-header type="other"></shop-header>
      <page-title title="版本升级" sub-title="Version upgrade"></page-title>
    </div>
    <order-select ref="selectRefs" @changeObj="change"></order-select>
    <match ref="matchRefs" @changeObj="change">
    </match>
    <buy-info class="mt_60" ref="buyInfoRefs" @changeObj="change"></buy-info>
    <div class="footer">
      <confirm-order class="confirm-order" :total="totalPrice" @confirm="handleClick"></confirm-order>
    </div>
    <confirm-dialog :order-info="orderInfo" :form-data="addressInfo" @closeConfirm="closeConfirm" :total="totalPrice"
                    ref="confirmDiaRefs"/>
  </div>
</template>

<script setup>
import {getCurrentInstance, onMounted, reactive, ref} from "vue";
import ShopHeader from "@views/shop/components/shopHeader/index.vue";
import PageTitle from "@views/shop/components/pageTitle.vue";
import OrderSelect from "./components/selet.vue";
import Match from "@views/shop/order/components/match/index.vue";
import BuyInfo from "@views/shop/order/components/buyInfo/index.vue";
import ConfirmOrder from "@views/shop/order/components/confirmOrder.vue";
import ConfirmDialog from "@views/shop/order/components/confirmDialog/index.vue";
import {createShopUpgradeOrder, getShopUpgradeOrderPrice} from '@utils/api/shop.js'
import {useStore} from "vuex";
import {listenBack} from "@views/shop/utils";
import {debounce} from 'lodash'

const orderInfo = ref({})
const addressInfo = ref({})
const buyInfoRefs = ref(null)
const selectRefs = ref(null)
const matchRefs = ref(null)
const store = useStore()
const {proxy} = getCurrentInstance();
listenBack()
const totalPrice = reactive({
  total: 0,
  diff: 0
})
const confirmDiaRefs = ref(null)
const change = debounce(() => {
  let {chooseItem, code} = selectRefs.value
  let {tableData: list} = matchRefs.value
  let {formData} = buyInfoRefs.value
  if (formData.addressId) {
    addressInfo.value = formData
  }
  if(!chooseItem.countNumber) return
  getShopUpgradeOrderPrice({
    discountCode: code,
    contactUser: formData.name,
    contactMobile: formData.mobile,
    contactAddress: formData.address,
    list: list,
    seat: chooseItem.countNumber
  }).then(res => {
    if (res.code !== 0) proxy.$message.error(res.msg)
    totalPrice.total = proxy.$filters.toYuan(res.data.total, '元')
    totalPrice.diff = proxy.$filters.toYuan(res.data.diff, '元', true)
  })
}, 200)
const handleClick = () => {
  let {formData} = buyInfoRefs.value
  let {chooseItem, code} = selectRefs.value
  let {tableData: list} = matchRefs.value
  if (!formData.addressId) {
    if (list.findIndex(item => item.count > 0) !== -1) {
      proxy.$message.error('请选择收货地址')
      return
    }
  }
  if(!chooseItem.countNumber) {
    proxy.$message.error('增加设备使用数量至少为1')
    return;
  }
  list = list.filter(item => item.count > 0)
  createShopUpgradeOrder({
    discountCode: code,
    contactUser: formData.name,
    contactMobile: formData.mobile,
    contactAddress: formData.address,
    seat: chooseItem.countNumber,
    list: list,
    time: ''
  }).then(res => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    orderInfo.value = res.data
    confirmDiaRefs.value.showDia = true
  })
}

const closeConfirm = () => {
}
onMounted(() => {
  change()
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shopOrder",
});
</script>

<style lang="scss" src="./index.scss" scoped>

</style>
