<template>
  <div class="version-select">
    <h3 class="version-title">产品升级</h3>
    <div class="sub-desc mt_48 ">
      <h2 class="sub-title h2">当前设备使用数量:{{ baseInfo.seat }}</h2>
      <div>
        <div class="count-number">
          增加设备使用数量：
          <Count v-model="countNumber" :min="1"></Count>
        </div>
        <div class="sub-text">
          *增加设备使用数量，同原购买使用时长到期时间保持一致、便于金额计算和管理
        </div>
      </div>

    </div>
    <el-form
        label-width="300"
        :rules="rules"
        ref="formRef"
        :model="formData"
        class="order-form large-input mt_20"
    >
      <el-form-item
          label="生效-到期时间"
      >
        <div class="value">{{ startTime }}</div>
      </el-form-item>
      <el-form-item
          label="优惠码："
          prop="code"
          class="mt_48"
      >
        <el-input
            v-model="formData.code"
            type="text"
            class="large-input w360"
            placeholder="请输入优惠码"
            @blur="blurCode"
            v-elInput
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, reactive, ref, watch} from "vue";
import {checkCoupon} from '@utils/api/shop.js'
import {useStore} from "vuex";
import moment from 'moment'
import Count from "@views/shop/components/count.vue";

const countNumber = ref(1)

const store = useStore()
const props = defineProps({
  code: {
    type: String,
    default: ''
  },
  modelValue: {
    type: Object,
    default: () => {
    }
  }
})
const emit = defineEmits(['update:code', 'update:modelValue'])
const baseInfo = computed(() => store.state.baseic)
const startTime = computed(() => {
  return moment().format('YYYY-MM-DD') + ' 至 ' + baseInfo.value.outTime

})
const tabChecked = ref(0)
const {proxy} = getCurrentInstance();
const formData = reactive({
  //优惠码
  code: '',
})
//自定义优惠码校验规则
const validateCode = (rule, value, callback) => {
  if (value !== '') {
    checkCoupon({discountCode: value}).then((res) => {
      if (res.code === 9176) {
        callback(new Error(res.msg))
      } else if (res.code !== 0) {
        proxy.$message.error(res.msg)
      } else {
        callback()
      }
    })
  } else {
    callback()
  }
};
const rules = reactive({
  code: [
    {validator: validateCode, trigger: 'blur'}
  ],
});
const blurCode = () => {
  emit('update:code', formData.code)
}
watch(() => props.code, (newVal) => {
  if (newVal !== formData.code) formData.code = newVal
})

watch(countNumber, () => {
  emit('update:modelValue', {...props.modelValue, countNumber: countNumber.value})
})

</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "orderVersions",
});
</script>

<style lang="scss" scoped>
.version-select {
  width: 100%;

  .version-content {
    display: flex;
    align-items: center;
  }

  > .sub-desc {
    font-size: 18px;
    color: $shopFsColor2;
    display: flex;
    justify-content: space-between;

    .sub-title {
      line-height: 34px;
      font-size: 18px;
    }

    .count-number {
      display: flex;
      align-items: center;
      width: fit-content;
      margin-left: auto;
      color: $shopFsColor;
    }

    .sub-text {
      margin-top: 24px;
      font-size: 12px;
      color: #7d7e8e;
    }
    .sub-title {
      color: $shopFsColor;
      font-weight: normal;
    }

    .desc {
      > span {
        color: $shopFsColor;
      }
    }
  }
}
</style>
